import ThemeLayout from "../ThemeLayout";
import cover from "../../assets/img/bg-profile.jpg";
import picture from "../../assets/img/team-2.jpg";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useState } from "react";

const Profile = (args) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <ThemeLayout>
            <div className="col-md-3"></div>
            <div className="col-md-6">
                <div className="card card-profile">
                    <img
                        src={cover}
                        alt="Image placeholder"
                        className="card-img-top"
                    />
                    <div className="row justify-content-center">
                        <div className="col-4 col-lg-4 order-lg-2">
                            <div className="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
                                <a href="#">
                                    <img
                                        src={picture}
                                        className="rounded-circle img-fluid border border-2 border-white"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
                        <div className="d-flex justify-content-between">
                            <Button
                                className="btn btn-sm btn-info mb-0 d-none d-lg-block"
                                onClick={toggle}
                            >
                                Change Role
                            </Button>
                            <a
                                href="#"
                                className="btn btn-sm btn-info mb-0 d-block d-lg-none"
                            >
                                <i className="ni ni-collection"></i>
                            </a>
                            <Button
                                href="#"
                                className="btn btn-sm btn-danger float-right mb-0 d-none d-lg-block"
                            >
                                Block
                            </Button>
                            <a
                                href="#"
                                className="btn btn-sm btn-dark float-right mb-0 d-block d-lg-none"
                            >
                                <i className="ni ni-email-83"></i>
                            </a>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="row">
                            <div className="col">
                                <div className="d-flex justify-content-center">
                                    <div className="d-grid text-center">
                                        <span className="text-lg font-weight-bolder">
                                            22
                                        </span>
                                        <span className="text-sm opacity-8">
                                            Friends
                                        </span>
                                    </div>
                                    <div className="d-grid text-center mx-4">
                                        <span className="text-lg font-weight-bolder">
                                            10
                                        </span>
                                        <span className="text-sm opacity-8">
                                            Photos
                                        </span>
                                    </div>
                                    <div className="d-grid text-center">
                                        <span className="text-lg font-weight-bolder">
                                            89
                                        </span>
                                        <span className="text-sm opacity-8">
                                            Comments
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <h5>
                                Mark Davis
                                <span className="font-weight-light">, 35</span>
                            </h5>
                            <div className="h6 font-weight-300">
                                <i className="ni location_pin mr-2"></i>
                                Bucharest, Romania
                            </div>
                            <div className="h6 mt-4">
                                <i className="ni business_briefcase-24 mr-2"></i>
                                Solution Manager - Creative Tim Officer
                            </div>
                            <div>
                                <i className="ni education_hat mr-2"></i>
                                University of Computer Science
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3"></div>
            <Modal isOpen={modal} toggle={toggle} {...args}>
                <ModalHeader toggle={toggle}>Change Role</ModalHeader>
                <ModalBody>
                    <select className="form-control">
                        <option value="">Select Role</option>
                        <option value="1">Admin</option>
                        <option value="2">User</option>
                    </select>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Do Something
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </ThemeLayout>
    );
};

export default Profile;
