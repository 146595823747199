import { API_URL, BASE_URL, APP_NAME } from "./helpers/constants";

export default {
    development: {
        [BASE_URL]: "localhost:3000",
        [API_URL]: "https://usrati-api.datanetbpodemo.com/admin/",
        [APP_NAME]: "Usrati Admin",
    },

    staging: {
        [BASE_URL]: "https://usrati-admin.datanetbpodemo.com",
        [API_URL]: "https://usrati-api.datanetbpodemo.com/admin/",
        [APP_NAME]: "Usrati Admin",
    },
};
