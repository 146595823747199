import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        detail: {
            name: "umer",
        },
    },
    reducers: {
        setUserDetails: (state, { payload }) => {
            state.details = payload;
        },
    },
});

export const { setUserDetails } = userSlice.actions;

export default userSlice.reducer;
