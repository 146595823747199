import ThemeLayout from "../ThemeLayout";
import avatar from "../../assets/img/team-2.jpg";
import avatar2 from "../../assets/img/team-3.jpg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUsers } from "../../services/userService";

const User = () => {
    const [users, setUsers] = useState([]);
    const ListUsers = async () => {
        const response = await getUsers();
        setUsers(response);
        console.log(response);
    };
    useEffect(() => {
        ListUsers();
    }, []);

    const formattedDate = (date) =>
        new Date(date).toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
        });
    return (
        <ThemeLayout>
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>Users</h6>
                    </div>
                    <div className="card-body px-0 pt-0 pb-2">
                        <div className="table-responsive p-0">
                            <table className="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Name
                                        </th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Username
                                        </th>
                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Role
                                        </th>
                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Date Joined
                                        </th>
                                        <th className="text-secondary opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!!users &&
                                        users.map((user) => (
                                            <tr>
                                                <td>
                                                    <div className="d-flex px-2 py-1">
                                                        <div>
                                                            <img
                                                                src={avatar}
                                                                className="avatar avatar-sm me-3"
                                                                alt="user1"
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column justify-content-center">
                                                            <h6 className="mb-0 text-sm">
                                                                {user.name}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">
                                                        {user.email}
                                                    </p>
                                                </td>
                                                <td className="align-middle text-center text-sm">
                                                    {user.role == "user" ? (
                                                        <span className="badge badge-sm bg-gradient-secondary">
                                                            User
                                                        </span>
                                                    ) : (
                                                        <span className="badge badge-sm bg-gradient-success">
                                                            Admin
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="align-middle text-center">
                                                    <span className="text-secondary text-xs font-weight-bold">
                                                        {formattedDate(
                                                            user.created_at
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="align-middle">
                                                    <Link
                                                        to={`/profile/${user.id}`}
                                                        className="text-secondary font-weight-bold text-xs"
                                                        data-toggle="tooltip"
                                                        data-original-title="Edit user"
                                                    >
                                                        View
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeLayout>
    );
};

export default User;
