import axios from "axios";
import config from "../helpers/config";
import { API_URL } from "../helpers/constants";

const path = config.get(API_URL) + 'users';

export const getUsers = async (pageNo = 1, perPage = 5) => {
  try {
     const response = await axios
      .get(`${path}`, {
        params: {
            pageNo,
            perPage
        }
      })
      return response.data;
  } catch (error) {
    return error.response;
  }
}


