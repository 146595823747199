const Input = (props) => {
    return (
        <input
            type={props.type}
            className={props.classes}
            placeholder={props.placeholder}
            aria-label={props.placeholder}
            name={props.name}
            id={props.id}
        />
    );
};

export default Input;
