import { Link } from "react-router-dom";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { APP_NAME } from "../../helpers/constants";
import config from "../../env";

const Login = () => {
    const env = process.env.NODE_ENV || "development";
    const appName = config[env][APP_NAME];
    return (
        <>
            <main className="main-content  mt-0">
                <section>
                    <div className="page-header min-vh-100">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex flex-column mx-lg-0 mx-auto justify-content-center align-items-center">
                                    <div className="card card-plain">
                                        <div className="card-header pb-0 text-start">
                                            <h4 className="font-weight-bolder">
                                                Sign In
                                            </h4>
                                            <p className="mb-0">
                                                Enter your email and password to
                                                sign in
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <form role="form">
                                                <div className="mb-3">
                                                    <Input
                                                        type="email"
                                                        placeholder="Email"
                                                        classes="form-control form-control-lg"
                                                        name="email"
                                                        id="email"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Input
                                                        type="password"
                                                        placeholder="Password"
                                                        classes="form-control form-control-lg"
                                                        name="password"
                                                        id="password"
                                                    />
                                                </div>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="rememberMe"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="rememberMe"
                                                    >
                                                        Remember me
                                                    </label>
                                                </div>
                                                <div className="text-center">
                                                    <Button
                                                        type="button"
                                                        classes="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
                                                        buttonText="Sign in"
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Login;
