import HomeCard from "../../components/HomeCard";
import ThemeLayout from "../ThemeLayout";

const Home = () => {
    return (
        <ThemeLayout>
            <HomeCard
                heading="Total Users"
                value="55"
                day="since yesterday"
                iconClasses="text-primary"
                icon="fas fa-user-circle"
            />
            <HomeCard
                heading="Today's Money"
                value="55"
                day="since yesterday"
                iconClasses="text-danger"
                icon="fas fa-user-circle"
            />
        </ThemeLayout>
    );
};

export default Home;
