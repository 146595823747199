import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Page = (props) => {
    const user = useSelector((store) => store.user.detail);
    const { authenticate } = props;
    const [render, setRender] = useState(!authenticate);
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticate) {
            // check token exists or not
            let token = window.localStorage.getItem("token");

            if (token) {
                setRender(true);
            } else {
                navigate("/login");
            }
        }
    }, [authenticate, render]);
    // TODO: condition for calling api (using await)

    return <>{(render || !authenticate) && props.children}</>;
};

export default Page;
