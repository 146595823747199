const HomeCard = (props) => {
    return (
        <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
            <div className="card">
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-8">
                            <div className="numbers">
                                <p className="text-sm mb-0 text-uppercase font-weight-bold">
                                    {props.heading}
                                </p>
                                <h5 className="font-weight-bolder">
                                    {props.value}
                                </h5>
                            </div>
                        </div>
                        <div className="col-4 text-end">
                            <i
                                className={`${props.icon} fa-2x ${props.iconClasses} opacity-10`}
                                aria-hidden="true"
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeCard;
