import { APP_NAME } from "../../helpers/constants";
import config from "../../env";
import { Link } from "react-router-dom";
import sidebarLogo from "../../assets/img/logo.png";
import { useSelector } from "react-redux";
import MenuItem from "../../components/MenuItem";

const ThemeLayout = (props) => {
    const env = process.env.NODE_ENV || "development";
    const appName = config[env][APP_NAME];
    const urlPath = window.location.pathname;
    const selectedMenu = useSelector((store) => store.app.selectedMenu);
    return (
        <>
            <div className="min-height-300 bg-primary position-absolute w-100"></div>
            <aside
                className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 "
                id="sidenav-main"
            >
                <div className="sidenav-header">
                    <i
                        className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                        aria-hidden="true"
                        id="iconSidenav"
                    ></i>
                    <Link className="navbar-brand m-0" to="/">
                        <img
                            src={sidebarLogo}
                            className="navbar-brand-img h-100"
                            alt="main_logo"
                        />
                        <span className="ms-1 font-weight-bold">{appName}</span>
                    </Link>
                </div>
                <hr className="horizontal dark mt-0" />
                <div
                    className="collapse navbar-collapse  w-auto "
                    id="sidenav-collapse-main"
                >
                    <ul className="navbar-nav">
                        <MenuItem
                            label="Dashboard"
                            icon="ni ni-tv-2"
                            color="text-primary"
                            link="/"
                        />
                        <MenuItem
                            label="Users"
                            icon="fas fa-users"
                            color="text-warning"
                            link="/users"
                        />

                        {/*<li className="nav-item">
                            <a
                                className="nav-link "
                                href="./pages/billing.html"
                            >
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="ni ni-credit-card text-success text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">
                                    Billing
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link "
                                href="./pages/virtual-reality.html"
                            >
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="ni ni-app text-info text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">
                                    Virtual Reality
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " href="./pages/rtl.html">
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="ni ni-world-2 text-danger text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">RTL</span>
                            </a>
                        </li>
                        <li className="nav-item mt-3">
                            <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
                                Account pages
                            </h6>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link "
                                href="./pages/profile.html"
                            >
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="ni ni-single-02 text-dark text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">
                                    Profile
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link "
                                href="./pages/sign-in.html"
                            >
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="ni ni-single-copy-04 text-warning text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">
                                    Sign In
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link "
                                href="./pages/sign-up.html"
                            >
                                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="ni ni-collection text-info text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">
                                    Sign Up
                                </span>
                            </a>
    </li>*/}
                    </ul>
                </div>
            </aside>
            <main className="main-content position-relative border-radius-lg ">
                <nav
                    className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
                    id="navbarBlur"
                    data-scroll="false"
                >
                    <div className="container-fluid py-1 px-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
                                <li className="breadcrumb-item text-sm">
                                    <a
                                        className="opacity-5 text-white"
                                        href="#"
                                    >
                                        Pages
                                    </a>
                                </li>
                                <li
                                    className="breadcrumb-item text-sm text-white active"
                                    aria-current="page"
                                >
                                    Dashboard
                                </li>
                            </ol>
                            <h6 className="font-weight-bolder text-white mb-0">
                                Dashboard
                            </h6>
                        </nav>
                        <div
                            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
                            id="navbar"
                        >
                            <ul className="navbar-nav ms-md-auto pe-md-3 justify-content-end">
                                <li className="nav-item d-flex align-items-center">
                                    <a
                                        href="#"
                                        className="nav-link text-white font-weight-bold px-0"
                                    >
                                        <i className="fa fa-user me-sm-1"></i>
                                        <span className="d-sm-inline d-none">
                                            Muhammad Kabir Liaquat
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
                                    <a
                                        href="#"
                                        className="nav-link text-white p-0"
                                        id="iconNavbarSidenav"
                                    >
                                        <div className="sidenav-toggler-inner">
                                            <i className="sidenav-toggler-line bg-white"></i>
                                            <i className="sidenav-toggler-line bg-white"></i>
                                            <i className="sidenav-toggler-line bg-white"></i>
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item px-3 d-flex align-items-center">
                                    <a
                                        href="#"
                                        className="nav-link text-white p-0"
                                    >
                                        <i className="fa fa-cog fixed-plugin-button-nav cursor-pointer"></i>
                                    </a>
                                </li>
                                <li className="nav-item dropdown pe-2 d-flex align-items-center">
                                    <a
                                        href="#"
                                        className="nav-link text-white p-0"
                                        id="dropdownMenuButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <i className="fa fa-bell cursor-pointer"></i>
                                    </a>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
                                        aria-labelledby="dropdownMenuButton"
                                    >
                                        <li className="mb-2">
                                            <a
                                                className="dropdown-item border-radius-md"
                                                href="#"
                                            >
                                                <div className="d-flex py-1">
                                                    <div className="my-auto">
                                                        <img
                                                            src="./assets/img/team-2.jpg"
                                                            className="avatar avatar-sm me-3"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <h6 className="text-sm font-weight-normal mb-1">
                                                            <span className="font-weight-bold">
                                                                New message
                                                            </span>{" "}
                                                            from Laur
                                                        </h6>
                                                        <p className="text-xs text-secondary mb-0">
                                                            <i className="fa fa-clock me-1"></i>
                                                            13 minutes ago
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <div className="container-fluid py-4">
                    <div className="row">{props.children}</div>
                </div>
            </main>
        </>
    );
};

export default ThemeLayout;
