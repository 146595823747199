import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: "app",
    initialState: {
        selectedMenu: "Dashboard"
    },
    reducers: {
        setSelectedMenu: (state, { payload }) => {
            state.selectedMenu = payload;
        },
    },
});

export const { setSelectedMenu } = appSlice.actions;

export default appSlice.reducer;
