import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedMenu } from "../../redux/slices/appSlice";

const MenuItem = (props) => {
    const selectedMenu = useSelector((store) => store.app.selectedMenu);
    const dispatch = useDispatch();

    const selectMenuItem = () => {
        dispatch(setSelectedMenu(props.label));
    };

    return (
        <li className="nav-item" onClick={selectMenuItem}>
            <Link
                className={`nav-link ${
                    selectedMenu == props.label ? "active" : ""
                } `}
                to={`${props.link}`}
            >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i
                        className={`${props.icon} ${props.color} text-sm opacity-10`}
                    ></i>
                </div>
                <span className="nav-link-text ms-1">{props.label}</span>
            </Link>
        </li>
    );
};

export default MenuItem;
