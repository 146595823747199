import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Page from "./components/page/Page";
import Home from "./pages/Home";
import Login from "./pages/Login";
import "./assets/css/argon-dashboard.min.css";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/nucleo-svg.css";
import User from "./pages/User/Index";
import Profile from "./pages/Profile";

const App = () => {
    return <Routes>
        <Route path="/" element={<Page authenticate={false}><Home /></Page>} />
        <Route path="/users" element={<Page authenticate={false}><User /></Page>} />
        <Route path="/profile/:id" element={<Page authenticate={false}><Profile /></Page>} />
        <Route path="/login" element={<Page><Login /></Page>} />
    </Routes>;
};

export default App;
